.login {
  text-align: center;
  padding: 100px 0;
}

.formDiv {
  padding: 20px;
}
.otherSignIn {
  padding: 20px;
  max-width: 500px !important;
  display: block;
  margin: 0 auto;
}

.otherSignIn button {
  border-radius: 50px;
  border: 1px solid #000;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.otherSignIn button span {
  position: absolute;
  top: 18%;
  left: 3%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
}
.otherSignIn button .google {
  font-size: 20px;
  color: #0f5b8d;
}
.otherSignIn button .github {
  font-size: 20px;
  color: #000;
}
@media (max-width: 500px) {
  .otherSignIn > h3 {
    font-size: 24px;
  }
}
