.menu {
  padding: 20px 0;
  box-shadow: 0 0 5px #c4c4c4;
}
.menu__items > a {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 500;
}
.menu__items a button {
  justify-self: self-start !important;
}

.menu__icon {
  font-size: 30px;
  font-weight: 700;
}
.menu__icon:hover {
  color: #ff0601;
}

.navbar .container {
  width: 80%;
  max-width: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  margin-bottom: -61px;
  list-style: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* Navigation Bar Styles */
nav {
  padding: 10px; /* Padding around the navigation bar */
}

nav a {
  color: #fff; /* Text color for the links */
  text-decoration: none; /* Remove underlines from links */
  display: inline-block;
  position: relative; /* Make the parent link a positioning context */
}

nav a:hover {
  background-color: #333b40; /* Background color on hover */
}

/* Sub-links Styles */
nav ul {
  background-color: #333b40;
  list-style: none; /* Remove default list styles */
  margin: 0;
  padding: 23px 0px 0px 10px;
  position: absolute; /* Position the sub-links absolutely */
  top: 100%; /* Position below the parent link */
  left: 0; /* Align with the left edge of the parent link */
  display: none; /* Hide the sub-links by default */
}

nav a:hover ul {
  display: block; /* Show the sub-links when hovering over the parent link */
}

nav ul li {
  display: inline-block;
  margin: 10px 4px 9px 14px;
}

nav ul a {
  color: #fff; /* Text color for sub-links */
  text-decoration: none; /* Remove underlines from sub-links */
}

nav ul a:hover {
  background-color: #333b40;
  text-decoration: underline; /* Underline on hover for sub-links */
}

.sub-links__color {
  color: #fff !important;
}