.banner {
  margin-top: 70px !important;
  background: url('../../../images/banner.jpg');
  height: 93vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.banner__content {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translateY(-50%);
  max-width: 1111px;
  padding: 50px 40px 60px;
  background-color: #ffffffd7;
  border-radius: 5px;
  box-shadow: 0 0 5px #cccbcb;
}
.banner__content h3 {
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 700;
}

.button {
  background-color: #ff0800 !important;
  display: inline-block !important;
  border-color: #fff;
  border: none;
}

.button:hover {
  background-color: #ff4500 !important;
  border-color: #fff;
}
