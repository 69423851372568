.form {
  margin: 20px;
  /* background-color: #e6eafd; */
  border-radius: 10px;
}

.serviceFrom .form_group input {
  margin: 5px 0 !important;
  display: block;
  padding: 10px 20px !important;
  width: 100% !important;
  border: none;
  border-radius: 5px;
}
.form_group input:focus {
  outline: none;
}

.button.disable {
  background-color: #a2bdf8 !important;
  pointer-events: none !important;
}
.upload {
  border: none;
}
.upload {
  color: transparent;
}
.upload::-webkit-file-upload-button {
  visibility: hidden;
}
.upload::before {
  content: 'Upload Service Image';
  color: #1455b6cc;
  display: inline-block;
  background: #ccd8f1d3;
  border: 1px solid #308edbcc;
  border-radius: 3px;
  padding: 10px 20px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}
.upload:active {
  outline: 0;
}
.upload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.form label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}
.serviceFrom .form-top,
.serviceFrom .form-bottom {
  display: flex;
}
.serviceFrom .form_group {
  width: 50% !important;
  margin: 10px;
}

.text__area {
  display: block;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .form-top,
  .form-bottom {
    flex-direction: column;
  }
  .serviceFrom .form_group {
    width: 100% !important;
  }
}
