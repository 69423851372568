.bookData {
  max-width: 550px;
}
.bookData h3 {
  width: 100%;
  font-size: 20px;
  padding: 15px 20px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}
.bookData label {
  display: block !important;
  color: #6b7c93;
  font-weight: 500;
  letter-spacing: 0.025em;
  font-size: 20px;
}
.bookData h3,
.bookData h5 {
  color: #444547;
}

.payment {
  height: auto;
}
