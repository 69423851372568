/* . dashboard */
.sidebar {
  margin: 0;
  padding: 0;
  width: 230px;
  position: fixed;
  height: 100%;
  overflow: auto;
  background: url('../../../images//sidebar.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}
.sidebar::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #cce0fac2;
  z-index: -2;
}

.sidebar .sidebarLink {
  display: block;
  color: black;
  padding: 10px;
  text-decoration: none;
  background-color: #ebfffd88;
  margin: 10px;
  border-radius: 10px;
}
.sidebarLink span {
  margin: 0 8px;
  color: #333;
}

div.content {
  margin-left: 230px;
}

.home {
  display: block !important;
  text-align: center;
  width: 100% !important;
}
.home a {
  color: #ff0601 !important;
  font-size: 25px;
  font-weight: 700;
  padding: 10px 15px;
  display: block;
  background-color: #f0fffac9;
}
.home a:hover {
  color: #ff0601 !important;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
