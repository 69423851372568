.icon a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #ddd;
  margin: 10px;
  color: #ff0000;
}

.img-with-padding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.huile {
  background: url('../../../images/huile.jpg') center/cover;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  width: 100%;
  height: 40vh;
}